<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="10"
        md="8"
        lg="8"
      >
        <v-text-field
          v-model="choiceText"
          :label="aaa"
          @blur="setChoiceText"
        />
      </v-col>
      <v-col
        sm="2"
        md="4"
        lg="4"
      >
        <v-select
          v-model="point"
          :items="scoreList"
          label="배점"
          @blur="setChoicePoint"
        />
      </v-col>
      <div />
    </v-row>
    </v-row">
  </v-container>
</template>

<script>
export default {
  props: {
    index: Number,
    scoreList: Array,
    isChoice: Boolean,
    setting: Number
  },
  data() {
    return {
      aaa: `선택지 ${ this.index } 번`,
      choiceText: '',
      point: 0
    }
  },
  methods: {
    setChoiceText() {
      const choiceTextIndex = `${this.setting}_choiceText${this.index}`
      localStorage.setItem(choiceTextIndex, this.choiceText)
    },
    setChoicePoint() {
      const choicePointIndex = `${this.setting}_choicePoint${this.index}`
      localStorage.setItem(choicePointIndex, this.point)
    }
  }
}
</script>

<style></style>
